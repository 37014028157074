#check {
  display: none;
}

.menu_open img {
  opacity: 0;
  padding: 6px;
  top: 12px;
  width: 24px;
  position: fixed;
  cursor: pointer;
  transition: all 0.3s linear;
  z-index: 6;
}

.left-sidebar {
  width: 164px;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  height: 96%;
  min-height: 100vh;
  transition: box-shadow ease-in-out 0.2s, transform ease-in-out 0.2s;
  box-sizing: border-box;
  font-size: 13px;
}

.nav-btn {
  background-color: inherit;
  width: 100%;
  border: none;
  padding: 0%;
}

.side-nav {
  height: auto;
  max-width: 100%;
  position: sticky;
  margin: 50px 0px;
  padding: 20px 0px;
}

.side-nav-div {
  padding: 10px 0px;
}

.side-nav-div div {
  padding-left: 10px;
}

.side-nav-links {
  text-decoration: none;
  color: #3a3a3a;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  transition: 0.2s;
}

.side-nav-links:hover {
  color: black;
}

.active {
  font-weight: bolder;
  color: black;
  background-color: white;
  border-right: solid 3px orange;
}

@media screen and (max-width: 600px) {
  .left-sidebar {
    position: absolute;
    transform: translateX(-100%);
  }
  .menu_open img {
    opacity: 0.7;
  }

  #check:checked ~ .left-sidebar {
    transform: translateX(0);
    position: fixed;
  }
}

.main-bar {
  width: calc(100% - 300px - 24px);
  float: left;
  margin: 25px 0px;
  padding: 0;
}

.main-bar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-bar-header h1 {
  font-weight: 400;
}

.main-bar-header .ask-btn {
  padding: 10px 15px;
  border-radius: 4px;
  background-color: #009dff;
  color: white;
  border: none;
  text-decoration: none;
  transition: 0.3s;
}

.main-bar-header .ask-btn:hover {
  background-color: #0086d8;
}

.display-ques-container {
  min-width: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fdf7e2;
  border-bottom: solid 1px #e4e9ec;
}

.display-ques-container .display-votes-ans {
  padding: 20px;
}

.display-ques-container .display-votes-ans p {
  margin: 0%;
  text-align: center;
}

.display-ques-details {
  flex-grow: 1;
  padding: 0px 20px;
}

.ques-title-link {
  text-decoration: none;
  color: #037ecb;
  transition: 0.3s;
}

.ques-title-link:hover {
  color: #009dff;
}

.display-ques-details p {
  padding: 4px;
  margin: 0%;
}
.display-tags-time .display-tags {
  display: flex;
  flex-wrap: wrap;
}

.display-tags-time .display-tags p {
  margin: 2px;
  padding: 4px;
  font-size: 13px;
  background-color: #edeff0;
  color: #39739d;
}

.display-tags-time {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.display-tags-time .display-time {
  font-size: 13px;
}
@media screen and (max-width: 800px) {
  .main-bar {
    width: calc(100%);
    float: none;
  }
}

/* .loading + p {
  font-weight: bold;
  font-size: 18px;
  margin-left: 3px;
} */

.loading {
  font-weight: bold;
  --speed-of-animation: 0.9s;
  --gap: 6px;
  --first-color: #4c86f9;
  --second-color: #49a84c;
  --third-color: #f6bb02;
  --fourth-color: #f6bb02;
  --fifth-color: #2196f3;
  display: flex;
  align-items: center;
  width: 100px;
  gap: 6px;
  height: 100px;
}

.loading span {
  width: 4px;
  height: 50px;
  background: var(--first-color);
  animation: scale var(--speed-of-animation) ease-in-out infinite;
}

.loading span:nth-child(2) {
  background: var(--second-color);
  animation-delay: -0.8s;
}

.loading span:nth-child(3) {
  background: var(--third-color);
  animation-delay: -0.7s;
}

.loading span:nth-child(4) {
  background: var(--fourth-color);
  animation-delay: -0.6s;
}

.loading span:nth-child(5) {
  background: var(--fifth-color);
  animation-delay: -0.5s;
}

@keyframes scale {
  0%,
  40%,
  100% {
    transform: scaleY(0.03);
  }

  20% {
    transform: scaleY(0.6);
  }
}

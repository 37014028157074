.main-nav {
  min-height: 50px;
  width: 100%;
  margin: 0% auto;
  border-top: solid 3px #ef8236;
  box-shadow: 0px 1px 5px black;
  position: fixed;
  z-index: 5;
  top: 0%;
  left: 0%;
  background-color: #f8f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar {
  height: 100%;
  min-width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar .navbar-1 {
  display: flex;
  align-items: center;
}

.navbar .navbar-2 {
  display: flex;
  align-items: center;
}

.nav-logo {
  padding: 5px 25px;
}

.nav-item {
  margin: 0px 3px;
  font-size: small;
  font-weight: 500;
  text-decoration: none;
  color: rgb(69, 69, 69);
  transition: 0.2s;
}

.nav-btn {
  cursor: pointer;
  border-radius: 20px;
  padding: 10px 20px;
}

.nav-item:hover,
.slide-in-icon:hover {
  background-color: rgb(226, 226, 226);
}

.navbar .navbar-1 form {
  min-width: 100%;
  padding: 0px 12px;
  position: relative;
}

.navbar .navbar-1 form input {
  min-width: 100%;
  padding: 8px 10px 8px 32px;
  font-size: 13px;
  border: solid 1px black;
  border-radius: 3px;
}

.navbar .navbar-1 form .srch-icn {
  position: absolute;
  left: 25px;
  top: 8px;
}

.nav-links {
  padding: 7px 13px;
  border: solid 1px blue;
  border-radius: 3px;
  background-color: #e7f8fe;
  cursor: pointer;
}

.nav-links:hover {
  background-color: #d3e4eb;
}

@media screen and (max-width: 620px) {
  .navbar .navbar-1 form {
    display: none;
  }
}
